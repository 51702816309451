<template>
  <div>
     <div v-if="visual_phone == true">
      <van-row type=:flex justify="center">
        <van-col span="4"></van-col>
        <van-col span="16">
          <van-tag type="primary" class="top-tag">使用方法</van-tag>
        </van-col>        
        <van-col span="4"></van-col>
      </van-row>

<van-divider dashed />

      <van-row type="flex" class="each-row">
        <van-col span="1"></van-col>
        <van-col span="22">
          <van-tag type="primary" class="sub-tag">复制手机号到麦当劳APP的登录页面</van-tag>           
        </van-col>
        <van-col span="1"></van-col>
      </van-row>
      
      <van-row type="flex" class="each-row">
        <van-col span="6"></van-col>
        <van-col span="6">
          <van-icon name="down" size="15px"/>
        </van-col>
        <van-col span="6"></van-col>
      </van-row>

      <van-row type="flex" class="each-row">
        <van-col span="1"></van-col>
        <van-col span="22">
          <van-tag type="primary" class="sub-tag">麦当劳APP上点击发送验证码</van-tag> 
        </van-col>
        <van-col span="1"></van-col>
      </van-row>

      <van-row type="flex" class="each-row">
        <van-col span="6"></van-col>
        <van-col span="6">
          <van-icon name="down" size="15px"/>
        </van-col>
        <van-col span="6"></van-col>
      </van-row>

      <van-row type="flex" class="each-row">
        <van-col span="1"></van-col>
        <van-col span="22">
          <van-tag type="primary" class="sub-tag">步骤三：在本页停留等待获取验证码</van-tag> 
        </van-col>
        <van-col span="1"></van-col>
      </van-row>

      <van-row type="flex" class="each-row">
        <van-col span="6"></van-col>
        <van-col span="6">
          <van-icon name="down" size="15px"/>
        </van-col>
        <van-col span="6"></van-col>
      </van-row>

      <van-row type="flex" class="each-row">
        <van-col span="1"></van-col>
        <van-col span="22">
          <van-tag type="primary" class="sub-tag">获取验证码复制到麦当劳APP完成登录</van-tag> 
        </van-col>
        <van-col span="1"></van-col>
      </van-row>


<van-divider dashed />

      <van-row type="flex" class="each-row">
        <van-col span="1"></van-col>
        <van-col span="22">
          <van-tag type="primary" class="red-tag">如果长时间没有获取验证码，请重复操作一次。如在APP上发送两次还没收到验证码，联系客服退款</van-tag> 
        </van-col>
        <van-col span="1"></van-col>
      </van-row>

<van-divider dashed />

      <van-row type="flex" class="each-row">
        <van-col span="1"></van-col>
        <van-col span="22">
          <van-tag type="primary" class="blue-tag">复制下面手机号到麦当劳APP，麦当劳APP上点击发送验证码，在此页面等待30秒将自动获得验证码</van-tag> 
        </van-col>
        <van-col span="1"></van-col>
      </van-row>

      <van-row type="flex" class="each-row">
        <van-col span="1"></van-col>
        <van-col span="16" >
           <van-tag type="primary" class="phone-tag"> 手机号： <span> {{ phone }} </span> </van-tag> 
        </van-col>
        <van-col span="5">
          <van-button plain type="primary"
            class="copy-phone-btn iconfont icon-fuzhi"
            size="small"
            :data-clipboard-text="phone"
            @click="copyPhone()"
            >复制手机号</van-button>
        </van-col>
        
         <van-col span="1"></van-col>
      </van-row>
      <van-row type="flex" class="each-row">
        <van-col span="1"></van-col>
        <van-col span="13">
          <van-tag type="primary" class="verify-tag"> 验证码： <span> {{ verify }} </span> </van-tag> 
        </van-col>
        <van-col span="3">
          <van-button plain type="primary"
            size="small"
            @click="reflash()"
            >刷新</van-button>
        </van-col>
        <van-col span="5">
          <div v-if="visual_verify == true">
          <van-button plain type = "primary"
            class="copy-verify-btn iconfont icon-fuzhi"
            size="small"
            :data-clipboard-text="verify"
            @click="copyVerify()"
            >复制验证码</van-button>
          </div>
        </van-col>
      </van-row>

<van-divider contentPosition="center">注意事项</van-divider>

  <van-row type="flex" class="note-row">
        <van-col span="1"></van-col>
        <van-col span="22">
          1.确定填了地址再提交订单，若有运费重启APP尝试。
        </van-col>
        <van-col span="1"></van-col>
      </van-row>
      <van-row type="flex" class="note-row">
        <van-col span="1"></van-col>
        <van-col span="22">
          2.若重复几次发现还有运费，请申请退款重新拍一个。
        </van-col>
        <van-col span="1"></van-col>
      </van-row>
      <van-row type="flex" class="note-row">
        <van-col span="1"></van-col>
        <van-col span="22">
          3.账号使用有效期一个小时，请尽快使用。
        </van-col>
        <van-col span="1"></van-col>
      </van-row>
      <van-row type="flex" class="note-row">
        <van-col span="1"></van-col>
        <van-col span="22">
          4.一个账号只能免一单运费，请勿多拍。
        </van-col>
        <van-col span="1"></van-col>
      </van-row>
      <van-row type="flex" class="note-row">
        <van-col span="1"></van-col>
        <van-col span="22">
          5.账号的V金禁止使用，谢谢配合！
        </van-col>
        <van-col span="1"></van-col>
      </van-row>

     </div>
    <div v-else>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Clipboard from "clipboard";
import {Button} from 'vant'
import { Col, Row, Tag, Icon, Toast, Divider  } from 'vant';

export default {
  inject:['reload'],
  components: {
    vanButton:Button,
    vanCol:Col,
    vanRow:Row,
    vanTag:Tag,
    vanIcon:Icon,
    vanDivider:Divider 
  },

  data() {
    return {
      token:'',
      phone:'',
      verify:'等待中',
      notice_val:'',
      visual_phone:false,
      visual_verify:false,
    };
  },

  computed: {
  },


  mounted(){
  },

  methods: {

    verificationCode() {
      this.confirmContent = this.totalTime + "s后可关闭";
      window.console.log('confirmContent:' + this.confirmContent)
      let clock = window.setInterval(() => {
        this.totalTime--;
        this.confirmContent = this.totalTime + "s后可关闭";
        if (this.totalTime <= 0) {
          clearInterval(clock);
        }
      }, 1000);
    },

    closeClickNotice() {
      this.visual_notice = false;
    },

    copyPhone(){
      let clipboard = new Clipboard('.copy-phone-btn')
      clipboard.on('success',(e)=>{
        window.console.log(e)
        //window.console.log('复制成功.')  
        if ( // 返回判断的值
          (typeof this.notice_val == 'undefined')
                ||
          (this.notice_val == null)
                ||
          (this.notice_val == false)        //类似: !x
                ||
          (this.notice_val.length == 0)
                ||
          (this.notice_val == 0)            // 这里是判断 0，不需要刻意去掉
                ||
          (this.notice_val == "")
                ||
          (this.notice_val.replace(/\s/g,"") == "")
                ||
          (!/[^\s]/.test(this.notice_val))
                ||
          (/^\s*$/.test(this.notice_val))){
          window.console.log('no notice_val at all' )  
          Toast('复制成功')
        }else{
          window.console.log('notice_val.' + this.notice_val)  
          this.visual_notice = true
          this.verificationCode(); //进入当前界面就需要调用倒计时方法
        }
        
        clipboard.destroy()
      })
      clipboard.on('error',(e)=>{
        window.console.log(e)
        //window.console.log('复制失败.')  
        clipboard.destroy()
      })
    },

    copyVerify(){
      let clipboard = new Clipboard('.copy-verify-btn')
      clipboard.on('success',(e)=>{
        window.console.log(e)
        //window.console.log('复制成功.')  
        Toast('复制验证码成功')
        clipboard.destroy()
      })
      clipboard.on('error',(e)=>{
        window.console.log(e)
        //window.console.log('复制失败.')  
        clipboard.destroy()
      })
    },

    reflash(){
      this.reload()
    }
    
  },

  sockets:{
    // 通信的name
    //这里是监听connect事件
    connect: function(){     
      //window.console.log('建立连接 & 加入房间.' + this.token)      
      this.$socket.emit('join',{'token':this.token})
    },

    disconnect: function(){
      //window.console.log('断开连接')
    },

    reconnect: function(){
      //window.console.log('重新连接')
      this.$socket.emit('conect')

    },

    error:function(){
      this.$socket.destroy()
    },

    close:function(){
      this.$socket.destroy()
    },

    chat_message: function(data){      
      //window.console.log('接收数据',data)
      let socket_verify = data['verify']
      if(typeof(socket_verify)=="undefined" || socket_verify == '' || socket_verify == null){        
        //window.console.log('none socket_verify')
      }else{
        //window.console.log('get socket_verify')
        this.verify = socket_verify
        this.visual_verify = true
      }
    }
  },
  created(){

    (function () {
      var viewportTag = null;
      var metaTags = document.getElementsByTagName('meta');
      for (var i = 0; i < metaTags.length; i++) {
        if (metaTags[i].getAttribute('name') === "viewport") {
          viewportTag = metaTags[i];
          break;
        }
      }
      if (!viewportTag) {
        viewportTag = document.createElement("meta");
        viewportTag.setAttribute('name', 'viewport');
      }

      var viewportTagContent = 'width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=0';

      // Detect if iOS device
      if (/(iPhone|iPod|iPad)/i.test(window.navigator.userAgent)) {
        // Get iOS major version
        var iosVersion = parseInt((window.navigator.userAgent).match(/OS (\d+)_(\d+)_?(\d+)? like Mac OS X/i)[1]);
        // Detect if device is running >iOS 11
        // iOS 11's UIWebView and WKWebView changes the viewport behaviour to render viewport without the status bar. Need to override with "viewport-fit: cover" to include the status bar.
        if (iosVersion >= 11) {
          viewportTagContent += ', viewport-fit=cover';
        }
      }

      // Update viewport tag attribute
      viewportTag.setAttribute('content', viewportTagContent);
    })()

    const shop = this.$route.params.shop;
    const token = this.$route.params.token;
    if(shop && token){
      this.token = token
      // 发ajax请求，用以获取数据，此处地址意思是查询 github中 vue 星数最高的项目
      const url = '/mds/md-wm/' + shop + '/' + token;    
      axios.get(url).then(
          res => {
            let result = res.data.data
            let code = result.code;
            if(code == '0'){
              let sim_code = result.sim_code;
              this.phone = sim_code;
              this.visual_phone = true
              // 连接 websocket，进入房间
              let return_verify = result.verify_code
              window.console.log(return_verify);
              if(typeof(return_verify)=="undefined" || return_verify == '' || return_verify == null){
                window.console.log('none return_verify');
              }else{
                window.console.log('got return_verify');
                this.verify = return_verify
                this.visual_verify = true
              }
              // 可能重新获得，so 还是要监听
              this.$socket.connect()
            }else{
              this.visual_phone = false
              window.console.log('failed');
            }
          }
      ).catch(
          rsp => {
            window.console.log(rsp);          
          },
      );
    }else{
      this.visual_phone = false
      window.console.log('noe allow');
    }
    
  },
  beforeDestroy() {
    this.$socket.emit('close',{'token':this.token})
    this.$socket.close()
  }
};
</script>

<style scoped>
.body {
  font-size: 0.3rem;
}
.each-row{
  font-size: 1px;;
}
.top-tag {
  font-size: 0.6rem;
  line-height: 0.8rem;
}

.sub-tag {
  font-size: 0.4rem;
  line-height: 0.6rem;
  color:black;
  background-color:yellow;
}

.red-tag {
  font-size: 0.4rem;
  line-height: 0.6rem;
  color:crimson;
  background-color:#DFE0E7;
}

.blue-tag {
  font-size: 0.4rem;
  line-height: 0.6rem;
  color:blue;
  background-color:white;
  margin-bottom: 10px;
}

.phone-tag {
  font-size: 0.55rem;
  line-height: 0.8rem;
  color:black;
  background-color:white;
  margin-top: 2px;
  margin-bottom: 3px;
}

.verify-tag {
  font-size: 0.55rem;
  line-height: 0.8rem;
  color:black;
  background-color:white;
  margin-top: 2px;
  margin-bottom: 3px;
}
.note-row{
  font-size: 0.35rem;
  color:gray;
  margin-bottom: 5px;
}
.dialog-button-bg {
  background: white;
  text-align: center;
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px;
}
</style>